/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  background: #f2f8fd;
  box-sizing: border-box;
  /* font-family: 'Outfit', sans-serif !important; */
  font-family: "Nunito", sans-serif !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
a {
  /* font-family: 'Outfit', sans-serif !important; */

  font-family: "Nunito", sans-serif !important;
  color: #1a1a1a;
}

.card {
  border-radius: 15px;
  box-shadow: rgb(214, 221, 227) 1px 0px 0px 0px,
    rgb(214, 221, 227) 0px 1px 0px 0px, rgb(214, 221, 227) -1px 0px 0px 0px,
    rgb(214, 221, 227) 0px -1px 0px 0px, rgba(63, 63, 68, 0.15) 0px 1px 3px 0px;
  margin-bottom: 0;
  border-radius: 4px;
}

.card-body {
  padding: 20px;
}

.lg-form input {
  padding: 15px;
  border-radius: 10px;
  background: #f2f8fd;
}

.lg-form .form-check input {
  border-radius: 4px;
  padding: unset;
}

.ic-input {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 22px;
  color: #7e8295;
}

.position-y {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.lg-ess h6 {
  cursor: pointer;
}

small {
  opacity: 0.8;
  font-weight: 500;
}

.ic-wrapper {
  height: 40px;
  background: #253b94;
  width: 40px;
  text-align: center;
  line-height: 38px;
  font-size: 20px;
  color: #fff;
  border-radius: 9px;
}

.modal-content {
  border-radius: 4px;
}

.invalid-feedback {
  font-size: 100%;
}

.lp-wrapper {
  max-width: 500px;
  width: 100%;
  margin: auto;
}

p {
  color: #5b626b;
  line-height: 1.4;
}

body[data-sidebar="dark"] .vertical-menu,
body[data-sidebar="dark"] .navbar-brand-box {
  background: #002645;
}

body[data-sidebar="dark"] .mm-active .active,
body[data-sidebar="dark"] .mm-active .active i {
  background-color: #0e3a5f;
  color: #fff !important;
}

.navbar-brand-box span.logo-lg img {
  scale: 1.6;
  margin-top: 5px;
}

#sidebar-menu ul li a i {
  min-width: 35px;
  font-size: 20px;
}

#sidebar-menu ul li a {
  font-size: 16px;
}

.page-title-box {
  padding: 15px 0px;
}

.page-title-box .page-title {
  font-size: 24px;
  margin-bottom: 5px;
}

.main-content {
  margin-left: 0px;
}

.vertical-collpsed .navbar-brand-box {
  padding: 0;
}

button#vertical-menu-btn {
  background: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
  border-radius: 50%;
  margin-left: -16px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

button#vertical-menu-btn i {
  font-size: 20px;
}

.app-search .form-control {
  height: 43px;
  background-color: #edeff1;
  margin-left: 10px;
}

.app-search span {
  font-size: 15px;
  line-height: 44px;
  color: #b2b2b2;
}

#page-topbar {
  background-color: #fff;
}

#page-header-notifications-dropdown {
  color: #fff;
}

.noti-icon i {
  color: #fff;
}

.noti-icon .badge {
  left: 19px;
}

.noti-icon .badge {
  position: absolute;
  top: 7px;
  right: 7px;
}

.dropdown-menu.show {
  top: 100% !important;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
}

.custom-hd-dd {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row-reverse;
}

.mini-stat .mini-stat-img {
  width: 68px;
  height: 68px;
  line-height: 62px;
  background: rgb(237 240 243);
  border-radius: 10px;
  text-align: center;
}

.mini-stat .mini-stat-img img {
  max-width: 44px;
}

.footer {
  height: unset;
  left: 200px;
  background-color: #ffffff;
  padding: 10px;
}

#sidebar-menu {
  padding: 20px 0 30px 0;
}

button#vertical-menu-btn i {
  font-size: 24px;
  margin-left: -4px;
}

body[data-sidebar="dark"] #sidebar-menu ul li a,
body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: #c8c8c8;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover {
  background-color: #0e3a5f;
  color: #fff;
}

.pl-height {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
  font-size: 24px;
  margin-left: 2px;
}

.custom-pg_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner-pg_search input.form-control {
  margin-left: 0;
  background: #fff;
  height: 43px;
  border: 1px solid #e2e2e2;
  border-radius: 50px;
  padding-left: 15px;
}

.inner-pg_search span {
  top: 0px;
}

/* button {
border-radius: 50px !important;
} */

/* .btn {
border-radius: 50px !important;
} */

.offcanvas-body .form-control,
.select2-selection__control {
  border-radius: 10px !important;
  background: #edf0f3 !important;
}

.off-footer {
  position: fixed;
  right: 15px;
  bottom: 15px;
  padding-top: 15px;
}

button.btn.btn-primary {
  border-color: #002645;
  background: #002645;
  border-radius: 10px;
}

.text-primary,
td a,
footer a {
  color: #233c93 !important;
}

footer a {
  color: #233c93 !important;
  text-decoration: underline !important;
}

.btn.btn-outline-primary:hover {
  background: #253d91;
  color: #fff !important;
}

.badge {
  border-radius: 50px !important;
  padding-inline: 5px;
}

.ut_ic {
  flex-shrink: 0;
  width: 45px;
  height: 45px;
  background: #edf0f3;
  color: #022644;
  border-radius: 50px;
  text-align: center;
  font-size: 24px;
  line-height: 43px;
}

.status-wrapper {
  right: 0;
}

.utilities-wrapper h6 {
  font-size: 16px;
}

.utilities-wrapper p {
  margin-bottom: 2px;
}

.kb-child {
  position: relative;
  max-width: 400px;
  width: 100%;
  background-color: #edeff1;
  border-radius: 10px;
  padding: 12px;
  flex-shrink: 0;
  /* margin-bottom: 10px; */
}

.events .set1 {
  background: #fff;
  padding: 12px;
  margin-top: 25px;
  border-radius: 10px;
  position: relative;
}

.post-float {
  position: absolute;
  top: -11px;
  right: 12px;
}

.events .set1 ul,
.ss-details ul {
  display: flex;
  gap: 5px !important;
  flex-wrap: wrap;
}

.events .set1 li {
  background-color: #edeff1;
  padding: 5px 10px;
  border-radius: 20px;
}

.ss-details li {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
}

ul.events-chip {
  display: flex;
  gap: 5px !important;
  flex-wrap: wrap;
}

ul.events-chip li {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
}

.question-count ul li {
  background-color: unset !important;
  color: #747474;
  border: 1px solid #747474;
  cursor: pointer;
  padding: 0 !important;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 2.1;
}

.session-details {
  height: calc(100vh - 176px);
}

.events .set1:first-child {
  margin-top: 0;
}

.events {
  max-height: calc(100vh - 416px);
  overflow: auto;
  margin-top: 10px;
  padding-top: 15px;
  margin-bottom: 5px;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: #022644;
}

.nav-tabs-custom .nav-item .nav-link::after {
  height: 4px;
  background: #022644;
}

.nav-tabs>li>a,
.nav-pills>li>a {
  color: #848484;
}

.dropdown-menu[style].custom-position {
  right: 0 !important;
}

.events img {
  margin-top: -5px;
}

.btn-primary {
  background-color: #002645;
}

.border-right {
  border-right: 1px solid #939393;
}

.border-bottom1 {
  border-bottom: 1px solid #939393;
}

.border-1 {
  border: 1px solid #939393;
}

.btn.btn-outline-primary:hover {
  background: #022644;
}

.btn.btn-outline-primary {
  background: unset;
  border-color: #022644;
  color: #022644 !important;
}

.remove-pd {
  padding: 0;
}

.remove-bg {
  background-color: unset;
}

.soft-sec-bg {
  background-color: #edeff1;
}

.common-pd {
  padding: 12px;
}

.ehp-height.card {
  height: calc(100vh - 233px);
}

.ehp-ch_height {
  height: calc(100vh - 263px);
  overflow: auto;
}

.ehp-w {
  flex: 1;
  max-width: unset;
}

textarea.form-control {
  font-size: 14px !important;
  border-radius: 10px;
}

.activity-wrapper {
  display: flex;
  gap: 15px;
}

/* .fo-left, .fo-right{
padding: 15px;
border-radius: 10px;
} */

.activity-wrapper .fo-left {
  width: 200px;
  border-right: 1px solid #ddd;
  /* background: #f2f8fd; */
  flex-shrink: 0;
  height: calc(100vh - 222px);
  overflow: auto;
}

.activity-wrapper .fo-right {
  flex-grow: 1;
}

.activity-wrapper .fo-left ul li {
  padding: 12px 10px;
  font-weight: 500;
  border-radius: 10px;
  transition: 0.3s;
}

.activity-wrapper .fo-left ul li:hover {
  background-color: #022644;
  color: #fff;
  cursor: pointer;
}

.activity-wrapper .fo-left ul li.active {
  background: #022644;
  color: #fff;
}

.custom-wt {
  max-width: 1400px;
}

.bg-dark-blue {
  background-color: #20365b !important;
}

.navbar-header {
  display: block;
  height: unset;
  padding: 0;
}

.alert-warning {
  background: #f9d49d;
}

.db-profile-section img {
  object-fit: cover;
  width: 35px;
  height: 35px;
}

.cs-fs {
  font-size: 30px !important;
  font-weight: 800 !important;
}

.custom-red {
  color: #d83d59;
}

.cs-success {
  background-color: #d9f6ed;
  border-radius: 12px;
}

ul.pg-tab li {
  padding: 5px 20px;
  border-radius: 40px;
  color: #8d9fb6;
  font-size: 16px;
  background-color: #e2e8f0;
}

ul.pg-tab li:first-child {
  padding: 0;
  border-radius: 0;
  color: #8d9fb6;
  font-size: 16px;
  background-color: unset;
}

ul.pg-tab li.active {
  color: #fff;
  background-color: #2196f3;
}

.custom-pb {
  height: 46px;
}

.custom-pb-child {
  height: 30px;
}

.progress-bar.bg-pgb-warning {
  background-color: #fcf3eb;
}

.progress-bar.bg-pgb-pink {
  background-color: #FAEBEE;
}

.progress-bar.bg-pgb-success {
  background-color: #e9f1ee;
}

.progress-bar.bg-pgb-dk-success {
  background-color: #009688;
}

.progress-bar.bg-pgb-dk-purple {
  background-color: #5e547a;
}

.progress-bar.bg-pgb-unset {
  background-color: #fcf3eb00;
}

.circle-scss {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #009688;
}

.circle-ppl {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #5e547a;
}

.pg-status {
  position: absolute;
  top: 12px;
  width: 100%;
  padding: 0 10px;
}

.pg-status-ft-2 {
  position: absolute;
  top: 8px;
  width: 100%;
  padding: 0 10px;
}

.card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.card-heading h4 {
  margin-bottom: 0;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
}

.card-heading p {
  margin-bottom: 0;
  font-size: 12px;
  margin-bottom: 0;
}

.card-heading a {
  margin-bottom: 0;
  font-size: 12px;
  margin-bottom: 0;
  color: #2196f3;
}

.cg-height {
  min-height: 378px;
  position: relative;
}

.the-charter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 42%);
}

.the-charter h4 {
  font-size: 26px;
  margin-bottom: 0;
  font-weight: 700;
}

.the-charter.sec {
  transform: translate(-50%, 10%);
  text-align: center;
}

.leader-banner {
  height: 203px;
  width: 100%;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  overflow: hidden;
  position: relative;
}

.leader-banner img {
  width: 100%;
  height: 100%;
  aspect-ratio: 2.11;
  object-fit: cover;
}

.leader-hdn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.issa-text {
  color: #20365b;
  font-size: 32px;
  font-weight: 800;
  text-align: center;
}

.leader-hdn {
  height: 70px;
  width: 100%;
  background-image: url(../src/assets/images/services-icon/lgbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lb-details ul.nav-tabs-custom {
  align-items: center;
}

.lb-details .nav-tabs-custom .nav-item a {
  height: 26px;
  padding: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 14px !important;
  font-weight: 800;
  background-color: #dae7f2;
}

.lb-details a.active.nav-link {
  background-color: #f2f8fd !important;
}

.lb-details .nav-tabs-custom .nav-item .nav-link::after {
  height: 0;
  background: #022644;
}

.leaderboard-filters .select2-selection__control {
  background: #dae7f2 !important;
  border-radius: 30px !important;
  font-size: 14px;
}

table.table.table-transparent tbody tr td {
  background: none;
  vertical-align: middle;
  font-weight: 800;
  color: #1a1a1a;
}

.leaderbd-contents {
  max-height: 465px;
  overflow: auto;
}

.custom-btn-green {
  padding: 0.25rem 1.5rem !important;
  border: none;
  color: rgb(255, 255, 255) !important;
  box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
  background: #63d3a6 !important;
  border-radius: 4px !important;
}

table.table.table-transparent tr {
  border-bottom: 1px solid rgb(181, 196, 215);
}

.lb-details {
  border: 1px solid rgb(181, 196, 215);
}

.hw-icon {
  background-color: #f2f8fd;
  width: 3rem;
  height: 3rem;
}

.hw-list ul li,
.hw-list ul a {
  background: #fff;
  padding: 25px;
  padding-bottom: 30px;
  box-shadow: rgb(214, 221, 227) 1px 0px 0px, rgb(214, 221, 227) 0px 1px 0px,
    rgb(214, 221, 227) -1px 0px 0px, rgb(214, 221, 227) 0px -1px 0px,
    rgba(63, 63, 68, 0.15) 0px 1px 3px;
  position: relative;
  margin-bottom: 25px;
}

.hw-icon {
  background-color: #f2f8fd;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ph_list-progressbar {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0;
}

.cs-btn-danger {
  background: #d83d59;
  color: rgb(255, 255, 255) !important;
  box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
  padding: 0.5rem 1.225rem !important;
  font-size: 1.225rem !important;
  font-weight: 800 !important;
  border-radius: 2rem !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase !important;
}

.cs-btn-danger:hover {
  background-color: #d83d59 !important;
}

.ucs-status {
  color: #8d9fb6;
  background: #f2f8fd;
  height: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 16px;
}

.ucs-hz-list {
  padding: 24px;
  background: #fff;
  box-shadow: 1px 0px 0px #d6dde3, 0px 1px 0px #d6dde3, -1px 0px 0px #d6dde3,
    0px -1px 0px #d6dde3, 0px 1px 3px rgba(63, 63, 68, 0.15);
  margin-bottom: 5px;
  border-radius: 4px;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  height: 100%;
  width: 100px;
  left: 0;
  background: linear-gradient(87deg, rgb(242, 248, 253) 32.41%, rgb(255 255 255 / 20%) 100%);
  color: #000;
  top: -2px;
  border-radius: 0 !important;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  height: 100%;
  width: 100px;
  right: 0;
  background: linear-gradient(270deg,
      rgb(240 246 251) 32.41%,
      rgb(255 255 255 / 62%) 100%);
  color: #000;
  top: -2px;
  border-radius: 0 !important;
}

.react-multiple-carousel__arrow::before,
.react-multiple-carousel__arrow::after {
  color: #000;
}

.react-multi-carousel-track {
  gap: 20px;
}

.pg-title {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  text-align: center;
}

.cs-mt {
  margin-top: 80px;
}

.clsses-fview-list {
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  max-width: 1280px;
  flex-wrap: wrap;
  margin: auto;
  /* padding-bottom: 100px; */
}

.clsses-fview-list .ucs-hz-list {
  width: 33.33%;
  margin-right: 24px;
  margin-bottom: 24px;
}

.ucs-attended {
  color: #237b56;
  background: #63d3a6;
  font-weight: 700;
}

.ucs-ongoing {
  color: #e68a2d;
  background: #f9d49d;
  font-weight: 700;
}

.ucs-missed {
  color: #ec6b66;
  background: #ffdbdb;
  font-weight: 700;
}

.list-bg {
  background-image: url(../src/assets/images/services-icon/bg-curve.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  min-height: 100vh;
}

.cl-pr-profile {
  position: relative;
  padding: 2rem 5rem 2rem 5rem;
}

.pr-name {
  background: #a9bfe9;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 #6985bb;
  color: #20365b;
  font-size: 12px;
  font-weight: 600;
  left: 0;
  bottom: 100px;
  letter-spacing: 0.36px;
  line-height: 13px;
  position: absolute;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  text-transform: uppercase;
}

.custom-prg-wrapper {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prg-success {
  background-color: #63d3a6;
}

.luminous-wht {
  color: #b5c4d7;
}

.cs-circle {
  width: 160px;
  height: 160px;
  background: #21365a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.custom-width-hold {
  max-width: 920px;
  margin: auto;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-75 {
  padding-bottom: 75px;
}

.text-success {
  color: #63d3a6 !important;
}

.bg-success {
  background-color: #159583 !important;
}

.mcr-success {
  background-color: #63d3a6;
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 30px;
}

#page-topbar {
  position: unset;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.text-yellow {
  color: rgb(254 240 138);
}

.fw-super-bold {
  font-weight: 900;
}

.fw-strong {
  font-weight: 800;
}

.summary-width-hold {
  max-width: 1024px;
  margin: auto;
}

.h-dg-img-wrapper {
  position: absolute;
  left: -50px;
  top: -50px;
}

.summary-cta {
  margin-left: 310px;
  margin-top: -20px;
  margin-bottom: 30px;
}

.summary-child-left {
  width: 80%;
}

.bigger-ft {
  font-size: 36px;
  font-weight: 800;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 30px;
  background-color: #000;
  display: block;
}

.summary-accordian .accordion-item {
  border: 0 !important;
}

.summary-accordian .accordion-button {
  background: unset;
  box-shadow: none;
  padding: 0;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700 !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  color: #1a1a1a;
}

.summary-accordian .accordion-button::after,
.summary-accordian .accordion-button::before {
  margin-left: unset;
  margin-right: 10px;
}

.custom-table thead tr th {
  color: #8d9fb6;
  background: #dae7f2;
  font-size: 24px !important;
  font-weight: 800;
  line-height: 25px;
  vertical-align: middle;
}

.custom-table tbody tr td {
  vertical-align: middle;
  border: 1px solid #dae7f2;
}

.it-success {
  background-color: #d9f7ed !important;
  color: #63d3a6 !important;
  font-size: 18px;
}

.sm-cta {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer;
}

.sm-cta a {
  box-shadow: none !important;
}

.it-danger {
  background-color: #ffdbdb !important;
  color: #ff0000 !important;
  font-size: 18px;
}

.cs-rsm .select2-selection__control {
  background-color: #dae7f2 !important;
  border-radius: 50px !important;
}

.assessement-section li.nav-item {
  background: #dae7f2;
  color: #b5c4d7;
  margin-right: 20px;
  font-size: 24px;
  height: 35px;
  width: 52px;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.assessement-section .nav-tabs-custom {
  border: 0;
}

.assessement-section .nav-tabs-custom .nav-item .nav-link::after {
  height: 0;
}

.assessement-section .nav-tabs-custom .nav-item .nav-link.active {
  background-color: unset;
  border: 2px solid #20365b;
  font-size: 24px;
  height: 35px;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.assessement-section .nav-tabs-custom .nav-item .nav-link {
  font-weight: 700;
  padding: 0;
}

.display-attempt2 {
  background: #d9f7ed !important;
  height: 35px;
  width: 52px;
  border-radius: 16px !important;
}

.display-attempt1 {
  background: #d9f7ed !important;
  color: #63d3a6;
  height: 35px;
  width: 52px;
  border-radius: 16px !important;
}

.display-attempt_fail {
  background: #ffdbdb !important;
  color: #ec6b66;
  height: 35px;
  width: 52px;
  border-radius: 16px !important;
}

.display-hold_active {
  color: #ffffff !important;
  background: #20365b !important;
  box-shadow: 0 0 0 4px #a9bfe9;
  height: 35px;
  border-radius: 16px !important;
}

.assessment-input {
  min-height: 45px;
  width: 200px;
  max-width: 300px;
  padding: 3px 7px !important;
  border: 1px solid gray;
}

.worng-ans {
  background: #ffdbdb;
  border: 2px solid #ec6b66;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #ffdbdb;
  margin-bottom: 10px;
}

.correct-ans {
  background: #d9f7ed;
  border: 2px solid #63d3a6;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #d9f7ed;
  margin-bottom: 10px;
}

.navigator-nxt {
  width: 380px;
  display: flex;
  padding: 30px;
  position: relative;
  background: #ffffff;
  align-items: center;
  border-radius: 24px !important;
  margin-bottom: 12px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-left: auto;
}

.navigator-wrapper {
  display: flex;
  justify-content: flex-end;
}

.nv-dg-smile {
  position: absolute;
}

.nv-dg-smile img {
  width: 190px;
}

.nv-dg-smile {
  position: absolute;
  right: 340px;
  bottom: 0px;
  z-index: 9;
}

.navigator-wrapper::before {
  content: "";
  background-image: url(../src/assets/images/services-icon/curve-lft.png);
  position: absolute;
  z-index: 999;
  top: 30px;
  right: 380px;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.btn-cst-success-md {
  padding: 0.25rem 1.5rem;
  border: none;
  color: rgb(255, 255, 255) !important;
  box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
  background-color: #63d3a6 !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
  border-radius: 8px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.btn-cst-danger-md {
  padding: 0.25rem 1.5rem;
  border: none;
  color: rgb(255, 255, 255) !important;
  box-shadow: rgb(188, 50, 69) 0px 4px 0px, rgba(146, 45, 63, 0.5) 0px 8px 12px !important;
  background-color: #d83d59 !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
  border-radius: 8px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.btn-cst-secondary-md {
  padding: 0.25rem 1.5rem;
  border: none;
  color: #8d9fb6 !important;
  box-shadow: hsl(207.5deg 48% 90.2%) 0px 4px 0px !important;
  background-color: #f2f8fd !important;
  letter-spacing: 0.05em;
  text-transform: uppercase !important;
  border-radius: 8px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

ul.dg-selector li {
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

li.selector-wrong {
  color: #ae534f;
  background: #ffdbdb !important;
  border: 2px solid #ec6b66;
  display: block;
  box-shadow: 0px 0px 0px 4px #ffdbdb !important;
}

.border-start-danger {
  border-left: 1px solid #ec6b66;
}

li.selector-warning {
  color: #237b56;
  background: #d9f7ed !important;
  border: 2px solid #f8b424;
  display: block;
  box-shadow: 0px 0px 0px 4px #ffdbdb !important;
}

.border-start-warning {
  border-left: 1px solid #f8b424;
}

.border-start-secondary {
  border-left: 1px solid #b5c4d7;
}

li.selector-success {
  color: #237b56;
  background: #d9f7ed !important;
  border: 2px solid #63d3a6;
  display: block;
  box-shadow: 0px 0px 0px 4px #d9f7ed !important;
}

.border-start-success {
  border-left: 1px solid #63d3a6;
}

.border-start-secondary {
  border-left: 1px solid #b5c4d7;
}

.emotion-wrapper ul li {
  padding: 0;
  border: 0 !important;
  box-shadow: none !important;
  margin-bottom: 0;
  position: relative;
}

.emotion-wrapper ul {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.subject-float {
  position: absolute;
  width: 50%;
}

.subject-float {
  position: absolute;
  width: 110px;
  left: 50%;
  transform: translate(-50%);
  top: 60px;
}

.video-wrapper div {
  width: 100% !important;
  height: 550px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-item {
  padding-inline: 15px;
}

.profile-wrapper {
  position: relative;
  max-width: 575px;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}

.cs-card {
  padding: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  margin-bottom: 16px;
}

.w-30 {
  width: 30%;
}

.obj-fit-cover {
  object-fit: cover;
}

.image-display-wrapper ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem 1rem;
}

.image-display-wrapper1 ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem 1rem;
}


/* .image-display-wrapper ul li,
.image-display-wrapper1 ul li {
  position: relative;
  border: 1px solid #0000;
}

.image-display-wrapper ul li.selected,
.image-display-wrapper1 ul li.selected {
  box-shadow: 0 0 0 5px #b5c4d7;
  border-radius: 50%;
  border: 1px solid #20365b;
}

.image-display-wrapper ul li:hover,
.image-display-wrapper1 ul li:hover {
  cursor: pointer;
  border: 1px solid #20365b;
  border-radius: 50%;
} */

.image-display-wrapper ul li img,
.image-display-wrapper1 ul li img {
  position: relative;
  border: 1px solid #0000;
}

.image-display-wrapper ul li.selected img,
.image-display-wrapper1 ul li.selected img {
  box-shadow: 0 0 0 5px #b5c4d7;
  border-radius: 50%;
  border: 1px solid #20365b;
}

.image-display-wrapper ul li img:hover,
.image-display-wrapper1 ul li img:hover {
  cursor: pointer;
  border: 1px solid #20365b;
  border-radius: 50%;
}

.slider {
  position: relative;
  width: 100%;
  height: 300px;
  /* Adjust height as needed */
}

.slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

button.next-btn.nav {
  position: absolute;
  left: 52%;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  background: 0;
  font-size: 24px;
  bottom: -10px;
}

button.prev-btn.nav {
  position: absolute;
  left: 44% !important;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  background: 0;
  font-size: 24px;
  bottom: -10px;
}

.primary-divider {
  height: 4px;
  background: #000;
  margin-top: 30px;
  margin-bottom: 30px;
}

ul.profile-tab {
  border-bottom: 0;
}

ul.profile-tab li a {
  background: #e7e7e7;
  padding: 0.5rem;
  border-radius: 0.5rem !important;

}

ul.profile-tab li a span img {
  object-fit: contain;
  width: 50px;
  height: 50px;
}

ul.profile-tab li a span i {
  object-fit: contain;
font-size: 39px;
margin: 5.5px;
opacity: .6;
}

/* .set-postion-abs {
position: absolute;
}

.profile-tb-ps-1 {
left: 50%;
transform: translate(-50%);
} */

.profile-tb-ps-2 {
  right: 70px;
  top: 93px;
}

.profile-tb-ps-3 {
  right: 70px;
  top: 213px;
}

.profile-tb-ps-4 {
  left: 70px;
  top: 93px;
}

.profile-tb-ps-5 {
  left: 70px;
  top: 213px;
}

/* .avatar-display {
margin: 70px 0 0 0;
} */

.avatar-default img {
  margin-top: 40px;
  margin-left: -4%;
}

.avatar-default {
  margin-top: -80px;
}

ul.profile-tab li a.active {
  background: #fde68a !important;
  border: 2px solid #2196f3 !important;
  box-shadow: 0 10px 15px -3px #fde68a, 0 4px 6px -4px #fde68a;
}

ul.profile-tab li a {
  margin-right: 20px;
}

.avatar-attachments {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
}

.head-float img {
  width: 445px;
  position: relative;
  z-index: 11;
}

.head-float {
  top: 2px;
  left: -224px;
}

.dress-float img {
  width: 128px;
  height: 236px;
  object-fit: contain;

}

.dress-float {
  top: 301px;
  left: -63px;
}

.companion-float img {
  width: 70px;
}

.companion-float {
  top: 452px;
  left: 91px;
}

.hand-float img,
.hand-float-revert img {
  width: 60px;
}

.female .hand-float img, .female .hand-float-revert img {
  width: 58px;
}

.female .hand-float {
  top: 326px;
  left: -77px;
}

.female .hand-float-revert {
  top: 329px;
  left: 24px;
}

.hand-float {
  top: 331px;
  left: -83px;
}

.hand-float-revert {
  top: 332px;
  left: 30px;
  transform: scaleX(-1);
}

.weapon-float img {
  width: 124px;
  position: relative;
  z-index: 10;
}

.weapon-float {
  top: 420px;
  left: -76px;
}

.btn-avt-selected {
  padding: 0.24rem 0.75rem;
  border: 1px solid rgb(218, 231, 242);
  color: rgb(255, 255, 255) !important;
  box-shadow: rgba(149, 167, 189, 0.8) 0px 4px 0px,
    rgb(190, 203, 220) 0px 8px 12px !important;
  background-color: #b5c4d7 !important;
  border-radius: 4px !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.btn-avt-select {
  padding: 0.24rem 0.95rem;
  border: 1px solid rgb(218, 231, 242);
  color: rgb(255, 255, 255) !important;
  border-color: #63d3a6 !important;
  box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
  background-color: #63d3a6 !important;
  border-radius: 4px !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.btn-avt-purchase {
  padding: 0.24rem 1.1rem;
  border: 1px solid rgb(218, 231, 242);
  color: rgb(255, 255, 255) !important;
  border-color: #63d3a6 !important;
  box-shadow: rgb(75, 185, 141) 0px 4px 0px !important;
  background-color: #63d3a6 !important;
  border-radius: 4px !important;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.profile-utlities-wrapper ul li {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  width: 8rem;
}

.profile-utlities-wrapper ul {
  /* display: flex;
flex-wrap: wrap;
gap: 20px 10px; */

  display: grid;
  gap: 20px 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.dress ul.list-unstyled.profile-utlities li img {
  width: 40px;
  height: 80px;
  object-fit: contain;
  margin: auto;
}

button.btn-avt-purchase img {
  height: 15px !important;
  width: 15px !important;
}

.companion ul.list-unstyled.profile-utlities li img {
  width: 58px;
  height: 80px;
  object-fit: contain;
}

.weapon ul.list-unstyled.profile-utlities li img {
  width: 105px;
  height: 80px;
  object-fit: contain;
}

.hand ul.list-unstyled.profile-utlities li img {
  width: 35px;
  height: 80px;
  object-fit: contain;
}

.head ul.list-unstyled.profile-utlities li img {
  /* width: 68px;
height: 80px; */
  width: 88px;
  height: 90px;
  object-fit: contain;
  margin: auto;
}

/* .head-float.female-hd img {
width: 118px;
}

.head-float.female-hd {
top: 6px;
left: -76px;
} */

.head-float.female-hd img {
  width: 444px;

}

.head-float.female-hd {
  top: -82px;
  left: -227px;
  /* top: 6px;
left: -36px; */

}

.my-homework ul li,
.my-homework ul a {
  margin-bottom: 0;
  box-shadow: none;
  border-bottom: 1px solid #DAE7F2;
}

.date-wrapper {
  box-shadow: 1px 0px 0px #D6DDE3, 0px 1px 0px #D6DDE3, -1px 0px 0px #D6DDE3, 0px -1px 0px #D6DDE3, 0px 1px 3px rgba(63,
      63, 68, 0.15);
  background-color: #fff;
  margin-bottom: 34px;
}

.ms-success {
  background-color: #009688 !important;
}

.assessment-width-hold {
  max-width: 1150px;
  margin: auto;
}

.modal-content {
  padding: 1.5rem;
}

.lg-input {
  padding: 16.5px 14px;
  background-color: #E8F0FF;
  border-radius: 4px;
  border: 1px solid #B2B8C3;
}

.lg-wrapper {
  width: 360px;
  margin: auto;
}

.lg-wrapper .form-check {
  margin-left: 39px;
}

.border-bottom-dark {
  border-bottom: 1px solid #8D9FB6;
}

.lg-wrapper .form-check-input {
  width: 16px;
  height: 16px;
  border: 1px solid #8D9FB6;
  margin-top: 2px;
  margin-right: 12px;
}

span.vertical-divider {
  height: 20px;
  width: 2px;
  background: #20365b;
}

.header-item.noti-icon {
  line-height: 2.5;
}

.text-blue {
  color: #2196f3;
}

.border-bottom {
  border-bottom: 1px solid #ddd !important;
}

.border-top {
  border-top: 1px solid #ddd !important;
}

.custom-pb,
.custom-pb-child {
  background-color: unset;
}

.horizontal-divider {
  width: 100%;
  height: 3px;
  background-color: #f2f8fd;
  margin: 10px 0;
  margin-bottom: 30px;
}

.custom-table.tb-custom-pd tbody tr td,
.custom-table.tb-custom-pd thead tr th {
  padding: 20px !important;
}

.custom-table.tb-custom-pd thead tr th {
  background-color: #F2F8FD;
}

.custom-table.tb-custom-pd tbody tr td {
  vertical-align: middle;
  border: 1px solid #f2f8fd;
}

.custom-mod-width {
  max-width: 600px;
}

.table.custom-table.noti-table thead tr th {
  font-size: 16px !important;
  background: #f2f8fd;
}

span.pgb-dk-purple {
  background-color: #5e547a;
}

.loader-container.body {
  background-color: rgba(224, 240, 246, 0.7) !important;
  z-index: 500 !important;
}

.loader.box,
.loader.body {
  background-color: rgba(224, 240, 246, 0.7) !important;
  padding: unset !important;
  border-radius: unset !important;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.direct_link {
  box-shadow: none !important;
  padding: unset !important;
  margin-bottom: unset !important;
  position: unset !important;
  cursor: pointer;
}

li.selector-general.active {
  color: #6f42c1;
  /* background: none; */
  border: 2px solid #6f42c1;

  box-shadow: 0px 0px 0px 4px #dddbdf !important;
}

.not_scroll {
  scroll-behavior: unset !important;
}

.dg-media p {
  margin-bottom: 0;
}

ul.nav-tabs-custom {
  width: 90%;
}

.width-controll {
  margin-left: auto;
}

.width-controll li input {
  display: flex;
  height: 45px;
}

.answererror {
  margin-left: auto;
  width: 200px;
  background: #ffdbdb;
  border: 2px solid #ec6b66;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #ffdbdb;
  margin-bottom: 10px;
  padding: 3px 7px !important;
  font-style: italic;
  font-size: 16px;
}

.answersuccess {
  margin-left: auto;
  width: 200px;
  background: #d9f7ed;
  border: 2px solid #63d3a6;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #d9f7ed;
  margin-bottom: 10px;
  padding: 3px 7px !important;
  font-style: italic;
  font-size: 16px;
}

.answereditor {
  width: 200px !important;
  margin-left: auto;
  padding: 3px 7px !important;
}

li.input_box.m_input_box {
  box-shadow: none;
}

.m_feedBack {
  padding: 7px 10px;
  margin-bottom: 15px;
}

.m_feedBack p {
  margin-bottom: 0;
}

li.input_box.e_input_box {
  box-shadow: none;
}

li.input_box.e_input_box .answererror {
  width: 100%;
}

li.input_box.e_input_box .answereditor {
  width: 100% !important;
}

li.input_box.e_input_box .answersuccess {
  width: 100% !important;
  background: #d9f7ed;
  border: 2px solid #63d3a6;
  border-radius: 3px;
  box-shadow: 0 0 0 4px #d9f7ed;
}

.choice-options_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  padding: 10px;
}

.choice-options_child {
  display: flex;
  align-items: flex-start;
  width: 19%;
  margin-right: 5px;
  line-height: 1;
  margin-bottom: 10px;
}

.choice-options_child div:first-child {
  margin-right: 5px;
}

ul.pg-tab {
  margin-right: 50px;
}

.pg-info .pg-tab li {
  margin-right: 15px;
  cursor: pointer;
  margin-bottom: 5px;
}

.custom-timer {
  float: right;
  margin-top: -54px;
}


.custom-timer h1 {
  font-weight: 800;
  color: #b7bed2;
}

.custom-timer2 h1 {
  font-weight: 800;
  color: #b7bed2;
}


b,
strong {
  font-weight: 800 !important;
  color: #000 !important;
}

.lb-details ul.nav-tabs-custom.nav-justified {
  width: 100%;
}

.worksheet-width-hold {
  max-width: 1120px;
  width: 100%;
  margin: auto;
}

/* canvas#fabric-canvas-1,  .canvas-container {
  width: 100% !important;
} */

.border-thick {
  border: 10px solid #eee;
}

.tool-bar-worksheet {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: hidden;
  padding: 1rem .75rem;
  margin-top: 0 !important;
}

.cs-tools button,
.fo-select {
  margin-right: 0.5rem;
  height: 40px;
  width: 40px;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
}

.fs-circle {
  width: 24px;
  height: 24px;
  border: 2px solid #2a2a2a;
  border-radius: 50%;
}

.fs-rectangle {
  width: 24px;
  height: 18px;
  border: 2px solid #2a2a2a;
}

.fs-square {
  width: 24px;
  height: 24px;
  border: 2px solid #2a2a2a;
}

.fo-select button {
  margin-right: 5px;
  padding: 0;
  display: block;
  height: unset;
  width: unset;
}

.fo-select {
  display: flex;
  align-items: center;
  margin-right: 0.9rem;
}

.fo-select select {
  width: 15px !important;
  border: 0;
}

.cs-tools svg {
  width: 26px;
  height: 22px;
}

.super-size {
  width: 30px !important;
  height: 38px !important;
}

.color-picker {
  height: 26px;
  width: 28px;
  margin-right: 1rem;

}

.color-picker input {
  width: 100%;
  height: 100%;
}

.fx-printer {
  width: 26px !important;
  height: 34px !important;
  margin-top: 2px;
}

.cs-tools button.active,
.fo-select.active {
  background: #f2f8fd !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.progress,
.progress-stacked {
  --bs-progress-bg: #dbdbdb !important;
}

.hw-status div {
  cursor: pointer;
}

.password-hider {
  position: absolute;
  right: 22px;
  top: 10px;
  cursor: pointer;
}

.password-hider i {
  color: #4c4c4c;
}

.e_feedBack p {
  margin: 0;
}

.e_feedBack {
  padding: 10px;
}

.m_feedBack p {
  margin: 0;
}

.m_feedBack {
  padding: 10px;
}


.super-profile_wrapper {
  display: flex;
  /* align-items: center; */
  width: 100%;
  margin-top: 30px;
  gap: 15px;
  height: 600px;
}

.content-left {
  width: 450px;
  flex-shrink: 0;
  min-height: 670px;
}

.content-right {
  flex-grow: 1;
}

/* .super-avatar-display {} */

.profile-utlities li img {
  margin: auto;
}



.lb-details ul.nav-tabs-custom.nav-justified {
  width: 100%;
}

.w-85{
width: 85% !important;
}

.avatar-wrapper{
  position: relative;
  text-align: center;
}

.default-image-span img{
  width: 150px;
  margin: auto;
}

.image-attachments{
  position: absolute;
  top: 0;
  left: 0;
}

.im-head, .im-body, .im-hand, .im-weapon, .im-companion {
  position: absolute;
}
.im-head {
  left: 20px;
  top: 6px;
  z-index: 9;
}

.im-head img {
  width: 110px;
}

.im-body {
  top: 99px;
  left: 55px;
}

.im-body img {
  width: 43px;
}

.im-hand {
  top: 109px;
  left: 47px;
}

.im-hand img {
  width: 21px;
}

.im-weapon {
  top: 140px;
  left: 50px;
}

.im-weapon img {
  width: 45px;
}

.im-companion {
  top: 136px;
  left: 112px;
}

.im-companion img {
  width: 30px;
}

.im-hand.hnd-right {
  left: 84px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.img-consolidate-display .avatar-default img {
  margin-left: -2px;
}


.img-consolidate-display {
  /* scale: .5;
  margin-top: -120px; */
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
  /* margin-top: -210px; */
  /* scale: .3; */
  margin-top: -183px;
  height: 550px;
}

.img-consolidate-display .content-left{
  min-height: unset;
}


.avatar-wrapper {
  /* position: relative;
  text-align: center;
  width: 25%;
  height: 300px; */
  position: relative;
  text-align: center;
  width: 16%;
  height: 150px;
  margin-left: -88px;
}

.invalid-feedback-all {
  /* display: none; */
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-invalid-color);
}



tr.active {
  border-left-color: #f1734f;
  border-left-width: thick;
}


.db-profile-section .profile_icon img {
  object-fit: cover !important;
  width: 70px !important;
  height: 70px !important;
}


.question_container p,
.question_container div {
  font-size: 20px !important;
  color: black;
}

.emotion-wrapper p {
  font-size: 12px !important;
  color: black;
}

.text-bg-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71/var(--tw-bg-opacity))
}

.text-bg-blue {
  --tw-bg-opacity: 1;
  background-color: #2196F3;
}

.small_input_box{
  box-shadow: unset !important;
  width: 200px;
  margin-left: auto;
  margin-bottom: unset !important;
}

.custom_check_box {
  -ms-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -o-transform: scale(1.4);
    transform: scale(1.4);
}

li.input_box.e_input_box textarea {
  height: 45px;
  font-size: 20px;
  font-style: italic;
}

.nv-dg-smile-avatar {
  position: absolute;
}

/* .nv-dg-smile-avatar img {
  width: 190px;
} */

.nv-dg-smile-avatar {
  position: absolute;
  right: 400px;
  bottom: -34px;
  z-index: 9;
  height: 100%;
  width: 0%;
}

.progress-bar.bg-bg-warning {
  background-color: #f8b425 !important;
}

.underline-link {
  text-decoration: underline !important;
  color: blue !important;  /* Optional: Color of the link */
}

label {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 3px;
  margin-left: 2px;
}

.answerQuide .accordion-body {
  padding: unset;
}


.table > :not(caption) > * > * {
  color: black !important;
}
.accordion .accordion-button {
  font-size: large;
  font-weight: 600;
}
.table th {
  font-weight: 600;
}

button.btn.btn-secondary {
  border-radius: 10px;
}
/* //student dynamic font size actvity containder start */


.stu-custom-font-size .question_container p, 
.stu-custom-font-size .question_container div {
  font-size: var(--custom-font-size, 20px) !important;
  color: black;
}

.stu-custom-font-size .cs-btn-danger{
  font-size: var(--custom-font-size, 20px) !important;
}

.assessement-section.stu-custom-font-size p, 
.assessement-section.stu-custom-font-size div,
.assessement-section.stu-custom-font-size h1,
.assessement-section.stu-custom-font-size h2,
.assessement-section.stu-custom-font-size h3,
.assessement-section.stu-custom-font-size h4,
.assessement-section.stu-custom-font-size h5,
.assessement-section.stu-custom-font-size h6 {
  font-size: var(--custom-font-size, 20px) !important;
}



.stu-custom-font-size li.input_box.e_input_box textarea {
  min-height: calc(var(--custom-font-size) * 2) !important;
  font-size: var(--custom-font-size, 20px) !important;
}

.stu-custom-font-size .width-controll li input {
  height: calc(var(--custom-font-size) * 2) !important;
  font-size: var(--custom-font-size, 20px) !important;
}



.stu-custom-font-size .font-size-10 {
  font-size: var(--custom-font-size, 10px) !important;
}
.stu-custom-font-size .font-size-12 {
  font-size: var(--custom-font-size, 12px) !important;
}
.stu-custom-font-size .font-size-14 {
  font-size: var(--custom-font-size, 14px) !important;
}
.stu-custom-font-size .font-size-16 {
  font-size: var(--custom-font-size, 16px) !important;
}

.stu-custom-font-size .font-size-15 {
  font-size: var(--custom-font-size, 15px) !important;
}

.stu-custom-font-size .font-size-18 {
  font-size: var(--custom-font-size, 18px) !important;
}
.stu-custom-font-size .font-size-20 {
  font-size: var(--custom-font-size, 220px) !important;
}
.stu-custom-font-size .font-size-22 {
  font-size: var(--custom-font-size, 22px) !important;
}
.stu-custom-font-size .font-size-24 {
  font-size: var(--custom-font-size, 24px) !important;
}
.stu-custom-font-size .font-size-26 {
  font-size: var(--custom-font-size, 26px) !important;
}

.stu-custom-font-size .font-size-28 {
  font-size: var(--custom-font-size, 28px) !important;
}
.stu-custom-font-size .font-size-30 {
  font-size: var(--custom-font-size, 30px) !important;
}
.stu-custom-font-size .font-size-32 {
  font-size: var(--custom-font-size, 32px) !important;
}
.stu-custom-font-size .font-size-36 {
  font-size: var(--custom-font-size, 36px) !important;
}
.stu-custom-font-size .font-size-38 {
  font-size: var(--custom-font-size, 38px) !important;
}
.stu-custom-font-size .font-size-40 {
  font-size: var(--custom-font-size, 40px) !important;
}

.image-display-wrapper ul li div:hover, .image-display-wrapper1 ul li div:hover{
  cursor: pointer;
  border: 1px solid #20365b;
  border-radius: 50%;
}
.image-display-wrapper ul li.selected div, .image-display-wrapper1 ul li.selected div {
  box-shadow: 0 0 0 5px #b5c4d7;
  border-radius: 50%;
  border: 1px solid #20365b;
}
/* //student dynamic font size actvity containder end */

